import App from "./App.vue";
const Digivigil = defineAsyncComponent(
  () => import("./components/digivigil/Main.vue")
);
const DigivigilIndex = defineAsyncComponent(
  () => import("./components/digivigil/Info.vue")
);
const DigivigilFocus = defineAsyncComponent(
  () => import("./components/digivigil/focus/Main.vue")
);
const DigivigilProgression = defineAsyncComponent(
  () => import("./components/digivigil/progression/Main.vue")
);

const Balek = defineAsyncComponent(() => import("./components/balek/Main.vue"));
const BalekIndex = defineAsyncComponent(
  () => import("./components/balek/Info.vue")
);
const BalekFocus = defineAsyncComponent(
  () => import("./components/balek/focus/Main.vue")
);
const BalekProgression = defineAsyncComponent(
  () => import("./components/balek/progression/Main.vue")
);

const Diaplode = defineAsyncComponent(
  () => import("./components/diaplode/Main.vue")
);
const DiaplodeIndex = defineAsyncComponent(
  () => import("./components/diaplode/Info.vue")
);
const DiaplodeFocus = defineAsyncComponent(
  () => import("./components/diaplode/focus/Main.vue")
);
const DiaplodeProgression = defineAsyncComponent(
  () => import("./components/diaplode/progression/Main.vue")
);

const About = defineAsyncComponent(() => import("./components/about/Main.vue"));
const AboutIndex = defineAsyncComponent(
  () => import("./components/about/index/Main.vue")
);
const AboutProgression = defineAsyncComponent(
  () => import("./components/about/progression/Main.vue")
);
const AboutVideos = defineAsyncComponent(
  () => import("./components/about/videos/Main.vue")
);
const AboutFocus = defineAsyncComponent(
  () => import("./components/about/focus/Main.vue")
);

import { createApp, defineAsyncComponent } from "vue";
import { RouteRecordRaw, createRouter, createWebHashHistory } from "vue-router";

const routes = [
  { path: "/", redirect: "/digivigil" },
  {
    path: "/digivigil",
    component: Digivigil,
    children: [
      {
        path: "",
        component: DigivigilIndex,
      },
      {
        path: "focus",
        component: DigivigilFocus,
      },
      {
        path: "progression",
        component: DigivigilProgression,
      },
      {
        path: "videos",
        component: AboutVideos,
      },
    ],
  },
  {
    path: "/balek",
    component: Balek,
    children: [
      {
        path: "",
        component: BalekIndex,
      },
      {
        path: "focus",
        component: BalekFocus,
      },
      {
        path: "progression",
        component: BalekProgression,
      },
      {
        path: "videos",
        component: AboutVideos,
      },
    ],
  },
  {
    path: "/diaplode",
    component: Diaplode,
    children: [
      { path: "", component: DiaplodeIndex },
      { path: "focus", component: DiaplodeFocus },
      { path: "progression", component: DiaplodeProgression },
    ],
  },
  {
    path: "/about",
    component: About,
    children: [
      {
        path: "",
        component: AboutIndex,
      },
      {
        path: "focus",
        component: AboutFocus,
      },
      {
        path: "progression",
        component: AboutProgression,
      },
      {
        path: "videos",
        component: AboutVideos,
      },
    ],
  },
];

const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHashHistory(),
  routes, // short for `routes: routes`
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 };
  },
});

createApp(App).use(router).mount("#main");
