<template>
  <div id="mainRouterView">
    <MainHeader />
    <router-view v-slot="{ Component, route }">
      <transition :name="route.meta.transition || 'fade'">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
  <transition name="bgFade">
    <div id="mainBackgroundView">
      <Bay />
    </div>
  </transition>
</template>
<script>
import { defineAsyncComponent } from "vue";

export default {
  name: "App",
  components: {
    MainHeader: defineAsyncComponent(() =>
      import("./components/MainHeader/MainHeader.vue")
    ),
    Bay: defineAsyncComponent(() =>
      import("./components/backgroundAnimatedBay/backgroundAnimatedBay.vue")
    ),
  },
  created: () => {
    // import('./registerServiceWorker').then((wb) => {
    //   if (wb !== null) {
    //     // wb.addEventListener("install", (event) => {
    //     //   console.log('🤖🤖ServiceWorker Installed Success')
    //     // });
    //   }
    // });
  },
};
</script>
<style>
html {
  overflow: hidden;
}

.MainLink {
  color: #205377;
  font-weight: bold;
}
.MainLink:hover {
  filter: invert();
}
body {
  overflow: hidden;
}

#mainRouterView {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
  z-index: 10;
  overflow: auto;
  background: rgba(255, 255, 255, 0.1);
}
#mainBackgroundView {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 1;
  opacity: 1;

  background-color: rgb(36, 104, 116);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.bgFade-enter-active,
.bgFade-leave-active {
  transition: opacity 10s ease;
}
.bgFade-enter-from,
.bgFade-leave-to {
  opacity: 0;
}

a {
  text-decoration: none;
  color: beige;
}

.MainInfoSummary {
  color: #205377;

  background: none;
  padding: 3px;
  border: none;

  border-left: none;
  margin: 0;
  font-size: 3vw;
  line-height: 3vw;
  display: block;
  padding-bottom: 20px;
}

#balekMainScreenshot {
  max-width: 80%;
}

.MainInfoDescription {
  color: #205377;
  font-size: 18pt;
  font-weight: normal;
  background: rgba(203, 210, 227, 0.84);
  padding: 10px;
  border: none;
  margin: 0;
  display: block;
}

.MainInfoTitleLogoImg {
  display: inline-block;

  height: 5vw;
}

.MainInfoTitleDiv {
  display: block;
  color: #205377;

  text-align: center;
  font-size: 6vw;
  line-height: 6vw;
  background: rgba(203, 210, 227, 0.84);

  padding-top: 10px;
  padding-bottom: 0px;
  border: none;
  margin: 0;
  font-weight: bold;
  vertical-align: center;
}

.MainInfoSubTitleDiv {
  display: block;
  color: #205377;

  text-align: center;
  font-size: 6vw;
  line-height: 6vw;
  background: none;

  padding-top: 10px;
  padding-bottom: 0px;
  border: none;
  margin: 0;
  font-weight: bold;
  vertical-align: center;
}

.InfoDiv {
  margin: 0;
  padding: 0;

  position: relative;
  background: rgba(0, 0, 139, 0.2);
  font-size: 24pt;
}
.MainHeaderMenuSpacer {
  border: none;
}
@media only screen and (max-width: 720px) {
  .MenuHeaderMainDiv {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .MenuSubHeaderMainDiv {
    position: fixed;
    bottom: 50px;
    left: 0;
    right: 0;
  }

  .MainInfoTitleDiv {
    position: fixed;
    bottom: 100px;
    left: 0;
    right: 0;
    z-index: 100;
  }

  .MainHeaderMenuSpacer {
    position: relative;
    display: block;
    background: rgba(203, 210, 227, 0.84);
    height: 200px;
  }
}
</style>
